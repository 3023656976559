// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-education-teacher-rules-js": () => import("./../../src/pages/education/teacher-rules.js" /* webpackChunkName: "component---src-pages-education-teacher-rules-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-charters-page-js": () => import("./../../src/templates/charters-page.js" /* webpackChunkName: "component---src-templates-charters-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-education-page-js": () => import("./../../src/templates/education-page.js" /* webpackChunkName: "component---src-templates-education-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-public-tours-page-js": () => import("./../../src/templates/public-tours-page.js" /* webpackChunkName: "component---src-templates-public-tours-page-js" */),
  "component---src-templates-schedule-page-js": () => import("./../../src/templates/schedule-page.js" /* webpackChunkName: "component---src-templates-schedule-page-js" */),
  "component---src-templates-teacher-feedback-page-js": () => import("./../../src/templates/teacher-feedback-page.js" /* webpackChunkName: "component---src-templates-teacher-feedback-page-js" */),
  "component---src-templates-teacher-registration-page-js": () => import("./../../src/templates/teacher-registration-page.js" /* webpackChunkName: "component---src-templates-teacher-registration-page-js" */)
}

